<template>
  <div style="height: 800px; width: auto" v-html="poHtmlCode" />
</template>

<script>
const axios = require("axios");
export default {
  name: "Word",
  data() {
    return {
      message: " ",
      poHtmlCode: "",
      subject: "",
      userName: "",
      fileName: "",
      QRCodeUrl: "",
    };
  },
  created: async function () {
    const obj = {
      businessNumber: this.$route.query.businessNumber,
      reportNumber: this.$route.query.reportNumber,
      client: this.$route.query.client,
      projectName: this.$route.query.projectName,
      evaluateValue: this.$route.query.evaluateValue,
      takeEffect: this.$route.query.takeEffect,
      userId: this.$route.query.userId,
      id: this.$route.query.id,
    };

    const extraData = window.external.UserParams
      ? JSON.parse(window.external.UserParams)
      : {};


    try {
      axios({
        url: `${this.baseUrl}/xinyuan/fileRevision/stamp`,
        method: "post",
        data: {
          ...this.$decodeObject(obj),
          ...this.$decodeObject(extraData)
        },
      })
        .then((res) => {
          this.poHtmlCode = res.data.msg;
        })
        .catch(function (err) {
          this.poHtmlCode = JSON.stringify(err);
        });
    } catch (error) {
      this.poHtmlCode = JSON.stringify(error);
    }

    const userInfoRes = await axios({
      url: `${this.baseUrl}/xinyuan/user/userId/${obj.userId}`,
      method: "get",
    });

    this.userName = userInfoRes.data.data.name;

    axios({
      url: `${this.baseUrl}/xinyuan/fileRevision/${obj.id}`,
      method: "get",
    }).then(({ data }) => {
      this.QRCodeUrl = data.data.qrCodeUrl;
    });
  },
  methods: {
    //控件中的一些常用方法都在这里调用，比如保存，打印等等
    Save() {
      pageofficectrl.WebSave();
    },
    SaveAsPDF() {
      pageofficectrl.WebSaveAsPDF();
      alert("另存为PDF成功");
    },
    InsertSeal() {
      pageofficectrl.zoomseal.AddSeal("王鑫玉", false);
    },
    Audit() {
      pageofficectrl.word.SelectBookmark("PO_QRCode");
      pageofficectrl.word.InsertInlineWebImage(this.QRCodeUrl);
    },
    BeforeDocumentSaved() {
      var iCount = pageofficectrl.zoomseal.Count;
      if (iCount > 0) {
        for (let i = iCount - 1; i >= 0; i--) {
          pageofficectrl.zoomseal.Item(i).DeleteSeal();//删除印章
        }
      }
    }
  },
  mounted() {
    // 将vue中的方法赋值给window
    window.Save = this.Save;
    window.SaveAsPDF = this.SaveAsPDF;
    window.InsertSeal = this.InsertSeal;
    window.audit = this.Audit;
    window.BeforeDocumentSaved = this.BeforeDocumentSaved;

    window.AfterDocumentOpened = () => {
      pageofficectrl.ShowRevisions = false;
      pageofficectrl.FullScreen = true;

      window.audit();

      window.showRevisions = () => {
        pageofficectrl.ShowRevisions = !pageofficectrl.ShowRevisions;
      };
    }
  },
};
</script>
